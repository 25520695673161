import React, { useEffect, useState } from 'react';
import CustomAlert from './custom-alert';
import { getNazivKnjige } from './../services/api';

const AddBookToPackage = ({ onOk, onCancel }) => {
   const [naziv, setNaziv] = useState('');
   const [nazivPronadjen, setNazivPronadjen] = useState(false);
   const [inventarniBroj, setInventarniBroj] = useState('');

   const notFoundMessage = (
      <span style={{ fontSize: '1.4rem' }}>
         Naziv knjige za inventarni broj{' '}
         <span style={{ color: 'red' }}>{`${inventarniBroj}`}</span> nije
         pronađen.
      </span>
   );

   const fetchNazivKnjige = async () => {
      try {
         const { data: result } = await getNazivKnjige(inventarniBroj);
         setNaziv(result.naslov ? result.naslov : notFoundMessage);
         setNazivPronadjen(result.naslov ? true : false);
      } catch (ex) {
         console.log('error', ex.data);
         setNaziv(notFoundMessage);
         setNazivPronadjen(false);
      }
   };

   const minCharacters = 5;
   const delay = 250;

   useEffect(() => {
      if (inventarniBroj.length < minCharacters) setNaziv('');
      if (inventarniBroj.length < minCharacters && inventarniBroj !== '')
         return;
      const delayDebounceFn = setTimeout(() => {
         if (inventarniBroj.length > 0) fetchNazivKnjige();
      }, delay);

      return () => {
         clearTimeout(delayDebounceFn);
      };
   }, [inventarniBroj]); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <>
         <CustomAlert
            title={`Dodavanje knjige u paket`}
            onOk={() => onOk(naziv, inventarniBroj)}
            onCancel={onCancel}
            btnOk='Dodaj'
            btnCancel='Odustani'
            btnOkDisabled={inventarniBroj.length === 0 || !nazivPronadjen}
         >
            <div className='form-group'>
               <label htmlFor=''>Inventarni broj knjige</label>
               <input
                  autoFocus
                  type='text'
                  className='form-control'
                  placeholder='Skenirajte ili unesite inventarni broj knjige...'
                  style={{ fontSize: '1.6rem' }}
                  onChange={({ target: input }) => {
                     setInventarniBroj(input.value.replace("'", '-'));
                  }}
                  value={inventarniBroj}
               />
            </div>
            <div className='form-group mt-4'>
               <span
                  style={{
                     fontWeight: 700,

                     color: '#c30e60'
                  }}
               >
                  {naziv}
               </span>
            </div>
         </CustomAlert>
      </>
   );
};

export default AddBookToPackage;
