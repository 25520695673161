import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { Provider } from 'react-redux';
import configureStore from './store/configure-store';
import { HashRouter } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/styles.scss';

const store = configureStore();

ReactDOM.render(
   <Provider store={store}>
      <React.StrictMode>
         <HashRouter>
            <App />
         </HashRouter>
      </React.StrictMode>
   </Provider>,
   document.getElementById('root')
);
