import React from 'react';
import { motion } from 'framer-motion';
import { v4 as uuid } from 'uuid';
import CustomAlert from './custom-alert';
import { stanjeKnjige } from './../services/common-data';
import { useSelector } from 'react-redux';

const PreviewPackageBooks = ({
   knjige,
   onOk,
   onCancel,
   onStanjeKnjigeChange
}) => {
   const { zaduzeneKnjige } = useSelector((state) => state.biblioteka);

   const isRented = (naziv) => zaduzeneKnjige.some((zk) => zk.naslov === naziv);

   return (
      <div className='preview-package-books'>
         <CustomAlert
            title={`Popis knjiga u paketu`}
            onOk={onOk}
            onCancel={onCancel}
            btnOk='Zaduži'
            btnCancel='Odustani'
         >
            {knjige.length === 0 && <p>U paketu nema knjiga.</p>}
            {knjige.length > 0 && (
               <table className='table table-striped table-sm'>
                  <thead>
                     <tr>
                        <th className='align-middle'>Naslov</th>
                        <th className='align-middle'>Inventarni broj</th>
                        <th className='align-middle'>Stanje</th>
                     </tr>
                  </thead>
                  <tbody>
                     {knjige.map((knjiga, i) => (
                        <motion.tr
                           key={uuid()}
                           initial={{ opacity: 0, x: -30 }}
                           animate={{ opacity: 1, x: 0 }}
                           transition={{
                              //ease: [1, 0.01, 0.49, 1.05],
                              duration: 0.5,
                              delay: i * 0.1
                           }}
                           style={
                              isRented(knjiga.naziv)
                                 ? {
                                      color: isRented(knjiga.naziv)
                                         ? '#fff'
                                         : '#000',
                                      background: isRented(knjiga.naziv)
                                         ? '#c30e60'
                                         : 'transparent'
                                   }
                                 : {}
                           }
                        >
                           <td className='align-middle'>
                              <div>{knjiga.naziv}</div>
                           </td>
                           <td className='align-middle'>
                              <div>{knjiga.inventarniBroj}</div>
                           </td>
                           <td className='align-middle'>
                              <select
                                 onChange={onStanjeKnjigeChange}
                                 id={knjiga.id}
                              >
                                 {stanjeKnjige.map((k) => (
                                    <option key={uuid()}>{k}</option>
                                 ))}
                              </select>
                           </td>
                        </motion.tr>
                     ))}
                  </tbody>
               </table>
            )}
         </CustomAlert>
      </div>
   );
};

export default PreviewPackageBooks;
