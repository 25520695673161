import React, { Component } from 'react';

class Input extends Component {
   state = {};
   inputElement = React.createRef();
   render() {
      const { name, label, error, required, ...rest } = this.props;

      const style = {
         marginBottom: '2px',
         color: 'black'
      };

      return (
         <div className='input'>
            <div className='form-group'>
               <label htmlFor={name} style={style}>
                  {label}
               </label>
               <input
                  ref={this.inputElement}
                  name={name}
                  id={name}
                  className='form-control form-control-sm'
                  {...rest}
               />
               {error && (
                  <div
                     className='alert alert-primary'
                     style={{
                        fontWeight: 700,
                        background: '#c30e60',
                        color: '#fff',
                        fontSize: '1.2rem',
                        borderRadius: '.3rem'
                     }}
                  >
                     {error}
                  </div>
               )}
            </div>
         </div>
      );
   }

   componentDidMount() {
      const { autoFocus } = this.props;
      if (autoFocus) this.inputElement.current.focus();
   }
}

/* autoFocus se samo doda kao atribut... bez vrijednosti... */
Input.defaultProps = {
   label: 'Label',
   placeholder: '',
   type: 'text',
   autoComplete: 'off',
   autoFocus: false
};
export default Input;
