export const navbarAnimation = {
    hidden: {
        y: -100
    },
    show: {
        y: 0,
        transition: {
            duration: 1,
            delay: 0.1,
            ease: 'easeOut'
        }
    }
};
