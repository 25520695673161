import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import {
    h1Variants,
    tableVariants
} from '../animations/student-information-animation';
import FramerMotionContainer from './framer-motion-container';

const StudentInformation = (props) => {
    const { odabraniStudent } = useSelector((state) => state.biblioteka);

    if (Object.keys(odabraniStudent).length === 0) {
        return <Redirect to='/homepage' />;
    }

    return (
        <div className='student-information'>
            <FramerMotionContainer variants={h1Variants}>
                <h1>{`${odabraniStudent.ime} ${odabraniStudent.prezime}`}</h1>
            </FramerMotionContainer>
            <FramerMotionContainer variants={tableVariants}>
                <table>
                    <tbody>
                        <tr>
                            <td>OIB</td>
                            <td>
                                {odabraniStudent.oib
                                    ? odabraniStudent.oib
                                    : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td>JMBAG</td>
                            <td>
                                {odabraniStudent.jmbag
                                    ? odabraniStudent.jmbag
                                    : '-'}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </FramerMotionContainer>
        </div>
    );
};

export default StudentInformation;
