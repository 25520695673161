import React from 'react';
import { useSelector } from 'react-redux';
import CustomAlert from './custom-alert';

const ConfirmDeleteBookFromPackage = ({ onOk, onCancel }) => {
   const { knjige, selectedBook } = useSelector((state) => state.biblioteka);

   const getNazivKnjige = (id) => {
      const book = knjige.find((k) => k.id === id);
      return book ? book.naziv : '';
   };

   return (
      <>
         <CustomAlert
            title='Potvrdite brisanje'
            onOk={onOk}
            onCancel={onCancel}
            btnOk='Obriši'
            btnCancel='Odustani'
         >
            Obristi knjigu{' '}
            <span style={{ fontWeight: 'bold' }}>
               {getNazivKnjige(selectedBook)}
            </span>
            ?
         </CustomAlert>
      </>
   );
};

export default ConfirmDeleteBookFromPackage;
