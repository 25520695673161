import React, { useEffect } from 'react';
import StudentData from '../components/student-data';
import FramerMotionContainer from './../components/framer-motion-container';
import { useDispatch } from 'react-redux';
import { setOdabraniStudent } from './../store/biblioteka';

const DetailsPage = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = `Detalji studenta`;
        return () => {
            dispatch(setOdabraniStudent({}));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='details-page center'>
            <FramerMotionContainer className='content-container'>
                <StudentData />
            </FramerMotionContainer>
        </div>
    );
};

export default DetailsPage;
