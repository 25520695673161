import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { loadCategories, setSelectedCategory } from './../store/biblioteka';
import { toast } from 'react-toastify';
import { spiningLoader } from './../services/toast';
import { motion } from 'framer-motion';

const ShowCategories = ({ onAdd, onDelete, onSelect, editMode }) => {
   const { selectedCategory, kategorije } = useSelector(
      (state) => state.biblioteka
   );
   const dispatch = useDispatch();

   useEffect(() => {
      const interval = setTimeout(() => {
         toast(spiningLoader('Učitavanje kategorija...'), {
            autoClose: false,
            progress: 1
         });
      }, 1000);
      dispatch(loadCategories()).then((_kategorije) => {
         dispatch(
            setSelectedCategory(_kategorije.length > 0 ? _kategorije[0].id : '')
         );
         clearInterval(interval);
         toast.dismiss();
      });
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const handleSelectKategorija = ({ target: ddl }) => {
      dispatch(setSelectedCategory(+ddl.value));
   };

   return (
      <motion.div
         className='form-group'
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ delay: 0.25, duration: 1 }}
      >
         <div className='category-container'>
            <label htmlFor='kategorija'>Kategorija</label>
            {editMode && (
               <div className='category-buttons'>
                  <span className='add mr-2' onClick={onAdd}>
                     <FontAwesomeIcon
                        icon={faPlusSquare}
                        style={{
                           color: '#6363e0',
                           fontSize: '2rem'
                        }}
                     />
                  </span>

                  {kategorije.length > 0 && (
                     <span className='remove' onClick={onDelete}>
                        <FontAwesomeIcon
                           icon={faMinusSquare}
                           style={{ color: '#f00', fontSize: '2rem' }}
                        />
                     </span>
                  )}
               </div>
            )}
         </div>
         <select
            className='form-control'
            id='kategorija'
            onChange={handleSelectKategorija}
            value={selectedCategory}
         >
            {kategorije.map((k) => (
               <option key={`category_${k.id}`} value={k.id}>
                  {k.naziv}
               </option>
            ))}
         </select>
      </motion.div>
   );
};

export default ShowCategories;
