import { useEffect } from 'react';

const Logout = (props) => {
   useEffect(() => {
      localStorage.removeItem('korisnickoIme');
      localStorage.removeItem('timestamp');
      window.location = `${process.env.PUBLIC_URL}/#/login`;
   }, []); // eslint-disable-line react-hooks/exhaustive-deps
   return null;
};

export default Logout;
