import api from '../config/api.json';
import http from './http';
import { endpoints } from './enums';

export const url = api.baseURL;

export const getZaduzeneKnjige = (id) => {
    const data = {
        endpoint: endpoints.PREGLED_ZADUZENIH_KNJIGA,
        data: {
            id
        }
    };
    return http.post(url, data);
};

export const getStudenti = (trazi) => {
    const data = {
        endpoint: 'studenti',
        data: {
            trazi
        }
    };
    return http.post(url, data);
};

export const zaduziStudentuKnjigu = ({
    id,
    inventarniBroj,
    stanjeKnjige,
    korisnik
}) => {
    const data = {
        endpoint: endpoints.POSUDBA_VRACANJE_KNJIGA,
        data: {
            id,
            korisnik,
            posudba: [
                {
                    inventarniBroj,
                    stanjeKnjige
                }
            ]
        }
    };
    return http.post(url, data);
};

export const zaduziPaketKnjiga = (id, knjige, korisnik) => {
    const data = {
        endpoint: endpoints.POSUDBA_VRACANJE_KNJIGA,
        data: {
            id,
            korisnik,
            posudba: knjige
        }
    };
    console.log('DATA', data);
    return http.post(url, data);
};

export const razduziStudentuKnjigu = ({
    id,
    inventarniBroj,
    stanjeKnjige,
    korisnik
}) => {
    const data = {
        endpoint: endpoints.POSUDBA_VRACANJE_KNJIGA,
        data: {
            id,
            korisnik,
            vracanje: [
                {
                    inventarniBroj,
                    stanjeKnjige
                }
            ]
        }
    };
    return http.post(url, data);
};

export const getNazivKnjige = (inventarniBroj) => {
    const data = {
        endpoint: endpoints.NAZIV_KNJIGE_PREMA_INVENTARNOM_BROJU,
        data: {
            inventarniBroj
        }
    };
    return http.post(url, data);
};

export const login = ({ korisnickoIme, lozinka }) => {
    const data = {
        endpoint: endpoints.LOGIN,
        data: {
            korisnickoIme,
            lozinka
        }
    };
    return http.post(url, data);
};
