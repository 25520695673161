import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearBooks, loadBooks, setSelectedBook } from './../store/biblioteka';
import { motion } from 'framer-motion';
import { v4 as uuid } from 'uuid';

const ShowBooks = ({ onAdd, onDelete, editMode }) => {
   const { selectedPackage, knjige, paketi } = useSelector(
      (state) => state.biblioteka
   );
   const dispatch = useDispatch();

   useEffect(() => {
      if (selectedPackage) dispatch(loadBooks(selectedPackage));
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (selectedPackage) dispatch(loadBooks(selectedPackage));
      else dispatch(clearBooks());
   }, [selectedPackage]); // eslint-disable-line react-hooks/exhaustive-deps

   const handleSelectKnjiga = (bookId) => {
      dispatch(setSelectedBook(bookId));
      onDelete();
   };

   return (
      <motion.div
         className='form-group package-show-books'
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ delay: 0.75, duration: 1 }}
      >
         {paketi.length > 0 && (
            <div style={{ textAlign: 'right' }}>
               <button className='btn btn-primary' onClick={onAdd}>
                  Dodaj knjigu
               </button>
            </div>
         )}
         {paketi.length > 0 && knjige.length === 0 && (
            <div style={{ color: '#333', textAlign: 'right' }} className='mt-3'>
               U odabranom paketu nema knjiga
            </div>
         )}
         {knjige.length > 0 && (
            <motion.table
               className='table table-striped table-sm mt-4'
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ delay: 0.25, duration: 1 }}
            >
               <thead>
                  <tr>
                     <th className='align-middle'>Naslov</th>
                     <th className='align-middle'>Inventarni broj</th>
                     <th></th>
                  </tr>
               </thead>
               <tbody>
                  {knjige.map((knjiga, i) => (
                     <motion.tr
                        key={uuid()}
                        initial={{ opacity: 1, x: 0 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{
                           //ease: [1, 0.01, 0.49, 1.05],
                           duration: 0.5,
                           delay: i * 0.1
                        }}
                     >
                        <td className='align-middle'>
                           <div
                              style={{
                                 width: '70rem',
                                 fontWeight: '700'
                              }}
                           >
                              {knjiga.naziv}
                           </div>
                        </td>
                        <td className='align-middle'>
                           <div>{knjiga.inventarniBroj}</div>
                        </td>
                        <td>
                           <button
                              className='btn btn-sm btn-danger'
                              onClick={() => {
                                 handleSelectKnjiga(knjiga.id);
                              }}
                           >
                              Obriši
                           </button>
                        </td>
                     </motion.tr>
                  ))}
               </tbody>
            </motion.table>
         )}
      </motion.div>
   );
};

export default ShowBooks;
