import React from 'react';
import { motion } from 'framer-motion';

const CustomAlert = (props) => {
    const {
        onOk,
        onCancel,
        title,
        btnCancel = 'Odustani',
        btnOk,
        btnOkDisabled,
        alertMode = false
    } = props;

    const onFormSubmit = (e) => {
        e.preventDefault();
        onOk();
    };

    return (
        <motion.div
            className='custom-alert'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.25 }}
            exit={{ opacity: 0 }}
        >
            <form method='post' onSubmit={onFormSubmit}>
                <motion.div
                    className='custom-alert-popup'
                    initial={{ opacity: 1, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5 }}
                    transition={{
                        duration: 2,
                        delay: 0,
                        type: 'spring',
                        stiffness: 200
                    }}
                >
                    <div className='custom-alert-title'>{title}</div>
                    <div className='custom-alert-content'>{props.children}</div>
                    <hr />
                    <div className='custom-alert-footer'>
                        <motion.button
                            initial={{ opacity: btnOkDisabled ? 0.5 : 1 }}
                            animate={{ opacity: btnOkDisabled ? 0.5 : 1 }}
                            className='custom-alert-btn btnPrimary'
                            disabled={btnOkDisabled}
                            autoFocus
                            type='submit'
                        >
                            {btnOk}
                        </motion.button>
                        {!alertMode && (
                            <button
                                className='custom-alert-btn btnSecondary'
                                onClick={onCancel}
                                type='button'
                            >
                                {btnCancel}
                            </button>
                        )}
                    </div>
                </motion.div>
            </form>
        </motion.div>
    );
};

export default CustomAlert;
