import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { razduziKnjigu, ucitajZaduzeneKnjige } from '../store/biblioteka';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import CustomAlert from './custom-alert';
import ConfirmZaduziRazduzi from './confirm-zaduzi-razduzi';
import { obrisiZaduzeneKnjige } from './../store/biblioteka';
import { AnimatePresence, motion } from 'framer-motion';
import { nemaZaduzenihKnjigaAnimation } from '../animations/table-books-animation';
import FramerMotionContainer from './framer-motion-container';
import { getUser } from '../services/user';
import Pagination from './pagination';
import { paginate } from './../services/paginate';
import { paginationAnimation } from './../animations/search-result-animation';

const TableBooks = (props) => {
   const { zaduzeneKnjige, odabraniStudent, windowDimenion } = useSelector(
      (state) => state.biblioteka
   );
   const [knjigaZaRazduzivanje, setKnjigaZaRazduzivanje] = useState({});
   const [showAlert, setShowAlert] = useState(false);

   const dispatch = useDispatch();

   const isFirstRender = () => {
      const timeSpan = 1000;
      if (!localStorage.getItem('time')) return true;

      const firstRenderTime = parseInt(localStorage.getItem('time'));
      const nowRenderTime = new Date().getTime();
      return nowRenderTime - firstRenderTime < timeSpan;
   };

   useEffect(() => {
      localStorage.setItem('time', new Date().getTime());

      try {
         const { id } = props.match.params;
         dispatch(ucitajZaduzeneKnjige(id));
      } catch (error) {
         //console.log('error', error);
      }
      return () => {
         dispatch(obrisiZaduzeneKnjige());
         localStorage.removeItem('time');
      };
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const handleMakniSaPopisaPosudenih = (knjiga) => {
      const removeBook = { ...knjiga };
      setShowAlert(true);
      removeBook.korisnik = getUser();
      removeBook.id = odabraniStudent.id;
      setKnjigaZaRazduzivanje(removeBook);
   };

   const handleOk = () => {
      dispatch(razduziKnjigu(knjigaZaRazduzivanje));
      setShowAlert(false);
   };

   const handleCancel = () => {
      setShowAlert(false);
   };

   const [currentPage, setCurrentPage] = useState(1);
   const pageSize = windowDimenion.height <= 768 ? 5 : 7;
   const rentedBooks = paginate(zaduzeneKnjige, currentPage, pageSize);

   const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
   };

   // Uvijek ga vrati pa page1 nakon bilo koje promjene na zaduženim knjigama
   useEffect(() => {
      setCurrentPage(1);
   }, [zaduzeneKnjige]);

   return (
      <>
         <AnimatePresence>
            {showAlert && (
               <CustomAlert
                  title='Razduživanje knjige'
                  btnOk='Razduži'
                  btnCancel='Odustani'
                  onOk={handleOk}
                  onCancel={handleCancel}
               >
                  <ConfirmZaduziRazduzi
                     inventarniBroj={knjigaZaRazduzivanje.inventarniBroj}
                     naslov={knjigaZaRazduzivanje.naslov}
                     stanje={knjigaZaRazduzivanje.stanjeKnjige}
                  />
               </CustomAlert>
            )}
         </AnimatePresence>
         <div className='table-books'>
            {rentedBooks.length === 0 && (
               <FramerMotionContainer
                  className='empty'
                  variants={nemaZaduzenihKnjigaAnimation}
               >
                  Nema zaduženih knjiga
               </FramerMotionContainer>
            )}
            {rentedBooks.length !== 0 && (
               <table className='table table-sm table-striped'>
                  <thead>
                     <tr>
                        <th className='align-middle'>Naslov</th>
                        <th className='align-middle'>Stanje</th>
                        <th className='align-middle'>Inventarni broj</th>
                        <th>
                           {false && (
                              <button
                                 className='btn btn-warning btn-sm dismiss'
                                 onClick={() => {
                                    //console.log('klik');
                                 }}
                              >
                                 Razduži sve
                              </button>
                           )}
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     {rentedBooks.map((knjiga, i) => (
                        <motion.tr
                           key={uuid()}
                           initial={
                              isFirstRender() ? { opacity: 0, x: -30 } : {}
                           }
                           animate={isFirstRender() ? { opacity: 1, x: 0 } : {}}
                           transition={{
                              //ease: [1, 0.01, 0.49, 1.05],
                              duration: 0.5,
                              delay: i * 0.1
                           }}
                        >
                           <td className='align-middle'>
                              <div>{knjiga.naslov}</div>
                           </td>
                           <td className='align-middle'>
                              {knjiga.stanjeKnjige}
                           </td>
                           <td className='align-middle'>
                              <div>{knjiga.inventarniBroj}</div>
                           </td>
                           <td className='align-middle'>
                              <button
                                 className='btn btn-warning btn-sm'
                                 onClick={() => {
                                    handleMakniSaPopisaPosudenih(knjiga);
                                 }}
                              >
                                 Razduži
                              </button>
                           </td>
                        </motion.tr>
                     ))}
                  </tbody>
               </table>
            )}
            <FramerMotionContainer
               variants={paginationAnimation}
               style={{ width: '90rem', overflow: 'hidden' }}
            >
               <Pagination
                  onPageChange={handlePageChange}
                  itemsCount={zaduzeneKnjige.length}
                  pageSize={pageSize}
                  currentPage={currentPage}
               />
            </FramerMotionContainer>
         </div>
      </>
   );
};

export default withRouter(TableBooks);
