import React, { useEffect, useState } from 'react';

const Search = ({
   searchOnTyping,
   delay = 250,
   onSearch: handleOnSearch,
   minCharacters = 2
}) => {
   const [searchTerm, setSearchTerm] = useState('');
   const [typing, setTyping] = useState(false);
   if (typing) {
   }

   useEffect(() => {
      if (
         !searchOnTyping ||
         (searchTerm.length < minCharacters && searchTerm !== '')
      )
         return;
      const delayDebounceFn = setTimeout(() => {
         handleOnSearch(searchTerm);
         setTyping(false);
         // Send axios request here
      }, delay);

      return () => {
         setTyping(true);
         clearTimeout(delayDebounceFn);
      };
   }, [searchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div className='search'>
         <input
            autoFocus
            type='text'
            autoComplete='off'
            className='search-text'
            placeholder='Započnite unos prezimena ili imena studenta...'
            onChange={({ target: input }) => {
               setSearchTerm(input.value);
            }}
         />
      </div>
   );
};

export default Search;

//https://stackoverflow.com/questions/29103096/dynamic-attribute-in-reactjs
