export const nemaZaduzenihKnjigaAnimation = {
    hidden: {
        opacity: 0
    },
    show: {
        letterSpacing: '1rem',
        opacity: 1,
        transition: {
            delay: 0.1,
            duration: 0.5
        }
    }
};
