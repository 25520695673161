import React from 'react';
import { useSelector } from 'react-redux';
import CustomAlert from './custom-alert';

const ConfirmDeletePackage = ({ onOk, onCancel }) => {
   const { paketi, selectedPackage } = useSelector((state) => state.biblioteka);

   const getNazivPaketa = (id) => {
      const paket = paketi.find((p) => p.id === id);
      return paket ? paket.naziv : '';
   };

   return (
      <>
         <CustomAlert
            title='Potvrdite brisanje'
            onOk={onOk}
            onCancel={onCancel}
            btnOk='Obriši'
            btnCancel='Odustani'
         >
            Obristi paket{' '}
            <span style={{ fontWeight: 'bold' }}>
               {getNazivPaketa(selectedPackage)}
            </span>
            ?
         </CustomAlert>
      </>
   );
};

export default ConfirmDeletePackage;
