import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { ToastContainer } from 'react-toastify';
import Navbar from './components/navbar';
import DetailsPage from './pages/detailspage';
import Homepage from './pages/homepage';
import PackagesPage from './pages/packagepage';
import { AnimatePresence } from 'framer-motion';
// eslint-disable-next-line
import logo from './resources/logo.png';
import { useLocation } from 'react-router-dom';
import ErrorPage from './pages/error-page';
import Footer from './components/footer';
import LoginPage from './pages/login-page';
import ProtectedRoute from './components/protected-route';
import Logout from './components/logout';
import { idleTimeHasExpired, idleTimeout } from './services/user';
import ErrorBoundary from './components/error-boundary';
import { useDispatch } from 'react-redux';
import { setWindowDimensions } from './store/biblioteka';

const App = () => {
   const toastSpeed = 2500;
   const location = useLocation();
   let time = null;

   useEffect(() => {
      document.onmousemove = resetTimer;
      document.onkeypress = resetTimer;

      if (idleTimeHasExpired()) {
         localStorage.removeItem('timestamp');
         window.location = `${process.env.PUBLIC_URL}/#/logout`;
      }
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const getURLPage = () => {
      const url = window.location.href;
      const arr = url.split('/');
      return arr[arr.length - 1];
   };

   const resetTimer = () => {
      localStorage.setItem('timestamp', new Date().getTime());
      clearTimeout(time);
      time = setTimeout(logout, idleTimeout);
   };

   const logout = () => {
      if (getURLPage() !== 'login')
         window.location = `${process.env.PUBLIC_URL}/#/logout`;
   };

   const dispatch = useDispatch();
   const [windowDimenion, detectHW] = useState({
      width: window.innerWidth,
      height: window.innerHeight
   });

   const detectSize = () => {
      detectHW({
         width: window.innerWidth,
         height: window.innerHeight
      });
   };

   useEffect(() => {
      window.addEventListener('resize', detectSize);
      dispatch(setWindowDimensions(windowDimenion));

      return () => {
         window.removeEventListener('resize', detectSize);
      };
   }, [windowDimenion]); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div className='app'>
         <ErrorBoundary>
            <ToastContainer
               position='bottom-center'
               autoClose={toastSpeed}
               toastClassName='myToast'
               closeButton='none'
            />
            <Navbar />
            <AnimatePresence exitBeforeEnter={true} initial={true}>
               <Switch location={location} key={location.pathname}>
                  <Route path='/login' component={LoginPage} />
                  <Route path='/logout' component={Logout} />
                  <ProtectedRoute path='/homepage' component={Homepage} />
                  <ProtectedRoute path='/packages' component={PackagesPage} />
                  <ProtectedRoute path='/error' component={ErrorPage} />
                  <ProtectedRoute
                     path='/details/:id'
                     exact
                     component={DetailsPage}
                  />
                  <Redirect from='/' to='/homepage' exact />
                  <Redirect to='/not-found' />
               </Switch>
            </AnimatePresence>
            <Footer />
         </ErrorBoundary>
      </div>
   );
};

export default App;

Storage.prototype.setObject = function (key, value) {
   this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key) {
   var value = this.getItem(key);
   return value && JSON.parse(value);
};
