import React from 'react';
import algebraLogo from '../resources/Algebra-logo-small.png';
import { motion } from 'framer-motion';

const AlgebraLogo = (props) => {
    return (
        <motion.div
            className='algebra-logo'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 3, delay: 0.5 }}
        >
            <img src={algebraLogo} alt='logo' />
        </motion.div>
    );
};

export default AlgebraLogo;
