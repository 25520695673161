import React from 'react';
import FramerMotionContainer from '../components/framer-motion-container';
import { loginUserSchema } from '../validation-schemas/login-user';
import Form from './../components/form';
import { login } from '../services/api';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

class LoginPage extends Form {
    state = {
        validationData: {
            Username: '',
            Password: ''
        },
        errors: {},
        loginInfo: ''
    };

    schema = loginUserSchema;

    componentDidMount() {
        document.title = `Prijava`;
    }

    doSubmit = async () => {
        const { Username: korisnickoIme, Password: lozinka } =
            this.state.validationData;

        try {
            await login({ korisnickoIme, lozinka });
            localStorage.setItem('korisnickoIme', korisnickoIme);

            const { history } = this.props;
            history.push('/homepage');
        } catch (ex) {
            this.setState({ loginInfo: 'Neispravni korisnički podaci' });
        }
    };

    render() {
        const { loginInfo } = this.state;
        return (
            <div className='login-page center'>
                <FramerMotionContainer
                    className='content-container'
                    style={{
                        width: '600px',
                        height: '300px',
                        minHeight: '400px'
                    }}
                >
                    <h1>Prijava</h1>
                    <form onSubmit={this.handleFormSubmit} autoComplete='off'>
                        {this.renderInput('Username', 'Korisničko ime', {
                            autoFocus: true,
                            autoComplete: 'off'
                        })}
                        {this.renderInput('Password', 'Lozinka', {
                            type: 'password'
                        })}
                        {this.renderSubmitButton(
                            <span>
                                <FontAwesomeIcon icon={faSignOutAlt} />
                                <span className='ml-2'>Prijava</span>
                            </span>,
                            this.validateForm()
                        )}
                    </form>
                    {loginInfo && (
                        <>
                            <hr />
                            <p style={{ color: '#000' }}>{loginInfo}</p>
                        </>
                    )}
                </FramerMotionContainer>
            </div>
        );
    }
}
//https://stackoverflow.com/questions/31079081/programmatically-navigate-using-react-router
export default withRouter(LoginPage);
