import React, { useEffect, useState } from 'react';
import FramerMotionContainer from './framer-motion-container';
import ShowCategories from './package-show-categories';
import {
   getKategorije,
   getPaketi,
   getKnjige
} from './../services/api-packages';
import ShowPackages from './package-show-packages';
import PreviewPackageBooks from './preview-package-books';
import { useSelector, useDispatch } from 'react-redux';

import { getUser } from '../services/user';
import { stanjeKnjige as bookState } from './../services/common-data';
import {
   zaduziPaket,
   setSelectedCategory,
   setSelectedPackage,
   resetPackages,
   deletePackage
} from './../store/biblioteka';

const variants = {
   hidden: {
      opacity: 0,
      y: -50
   },
   show: {
      y: 0,
      opacity: 1,
      transition: {
         duration: 1.5,
         type: 'spring'
      }
   }
};

const BorrowPackage = (props) => {
   const { odabraniStudent } = useSelector((state) => state.biblioteka);
   const dispatch = useDispatch();

   const [kategorije, setKategorije] = useState([]);
   const [selectedKategorija, setSelectedKategorija] = useState('');

   const [paketi, setPaketi] = useState([]);

   const [knjigePaketa, setKnjigePaketa] = useState([]);
   const [previewBooks, setPreviewBooks] = useState(false);
   const { selectedPackage } = useSelector((state) => state.biblioteka);

   useEffect(() => {
      fetchCategories();
      return () => {
         dispatch(resetPackages());
      };
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      getPaketiKategorije();
   }, [selectedKategorija]); // eslint-disable-line react-hooks/exhaustive-deps

   const fetchCategories = async () => {
      const { data } = await getKategorije();
      const result = data.data;
      if (result.length === 0) return;

      setKategorije(result);
      setSelectedKategorija(result[0].id);
   };

   const getPaketiKategorije = async () => {
      if (!selectedKategorija) return;
      const { data: sviPaketi } = await getPaketi(selectedKategorija);
      setPaketi(sviPaketi);
   };

   const handleSelectKategorija = ({ target: ddl }) => {
      dispatch(setSelectedCategory(ddl.value));
   };

   const handleSelectPaket = ({ target: ddl }) => {
      dispatch(setSelectedPackage(ddl.value));
   };

   const handleZaduziPaketPreview = async () => {
      const { data: result } = await getKnjige(selectedPackage);

      setKnjigePaketa(result.data);
      setPreviewBooks(true);
   };

   const handleZaduziPaket = async () => {
      const knjige = knjigePaketa.map((k) => {
         return {
            inventarniBroj: k.inventarniBroj,
            stanjeKnjige: k.stanjeKnjige ? k.stanjeKnjige : bookState[0]
         };
      });

      dispatch(zaduziPaket(odabraniStudent.id, knjige, getUser()));
      dispatch(deletePackage(selectedPackage)).then((response) => {
         const { updatedPackages } = response;
         dispatch(
            setSelectedPackage(
               updatedPackages.length > 0 ? updatedPackages[0].id : ''
            )
         );
      });

      setPreviewBooks(false);
   };

   const handleStanjeKnjigeChange = ({ target: ddl }) => {
      console.log('ddl', ddl);
      console.log('knjigePaketa', knjigePaketa);
      const foundedBook = knjigePaketa.find((k) => k.id === +ddl.id);
      foundedBook.stanjeKnjige = ddl.value;
   };

   return (
      <>
         {previewBooks && (
            <PreviewPackageBooks
               knjige={knjigePaketa}
               onCancel={() => setPreviewBooks(false)}
               onOk={handleZaduziPaket}
               onStanjeKnjigeChange={handleStanjeKnjigeChange}
            />
         )}
         <div className='borrow-package'>
            <FramerMotionContainer variants={variants}>
               <div className='row'>
                  <div className='col'>
                     <ShowCategories
                        kategorije={kategorije}
                        onSelect={handleSelectKategorija}
                     />
                  </div>
                  <div className='col'>
                     <ShowPackages
                        paketi={paketi}
                        onSelect={handleSelectPaket}
                     />
                  </div>
               </div>
               <div>
                  <button
                     className='form-control mt-2 btn-package-preview'
                     onClick={handleZaduziPaketPreview}
                     disabled={!selectedPackage}
                  >
                     Pregledaj knjige u paketu
                  </button>
               </div>
            </FramerMotionContainer>
         </div>
      </>
   );
};

export default BorrowPackage;
