import api from '../config/api-packages.json';
import http from './http';

const url = api.baseURL;

/*
      KATEGORIJA
*/
export const getKategorije = () => {
   return http.get(`${url}/${api.getAllCategory}`);
};

export const createKategorija = (naziv) => {
   return http.post(`${url}/${api.addCategory}`, { naziv });
};

export const deleteKategorija = (id) => {
   return http.get(`${url}/${api.deleteCategory}/${id}`);
};

/*
PAKETI
*/
export const getPaketi = (kategorija) => {
   const _url = `${url}/${api.getCategoryPackages}`;
   if (kategorija) return http.get(`${_url}/${kategorija}`);
   return http.get(_url);
};

export const createPaket = (naziv, kategorijaId) => {
   const paket = { naziv, kategorijaId };
   return http.post(`${url}/${api.addPackage}`, paket);
};

export const deletePaket = (id) => {
   return http.get(`${url}/${api.deletePackage}/${id}`);
};

/*
KNJIGE
*/
export const getKnjige = (paket) => {
   const _url = `${url}/${api.getAllBooks}`;
   if (paket) return http.get(`${_url}/${paket}`);
   return http.get(_url);
};

export const createKnjiga = (naziv, inventarniBroj, paketId) => {
   console.log('kreiram knjigu', naziv, inventarniBroj, paketId);
   return http.post(`${url}/${api.addBook}`, {
      naziv,
      inventarniBroj,
      paketId
   });
};

export const deleteKnjiga = (id) => {
   return http.get(`${url}/${api.deleteBook}/${id}`);
};
