import React from 'react';
import { motion } from 'framer-motion';

const defaultAnimation = {
   hidden: {
      scale: 1.1,
      opacity: 0,
      y: 20
   },
   show: {
      scale: 1,
      opacity: 1,
      y: 0,

      transition: {
         duration: 0.3,
         staggerChildren: 0.75,
         when: 'beforeChildren'
      }
   },
   exit: {
      opacity: 0,
      y: 0,
      transition: {
         duration: 0.2,
         ease: 'easeOut'
      }
   }
};

const FramerMotionContainer = ({
   className,
   children,
   variants,
   style,
   rest
}) => {
   return (
      <motion.div
         className={className ? className : ''}
         style={style ? style : {}}
         variants={variants ? variants : defaultAnimation}
         initial='hidden'
         animate='show'
         exit='exit'
         {...rest}
      >
         {children}
      </motion.div>
   );
};

export default FramerMotionContainer;
