import axios from 'axios';

//AXIOS can intecept errors
// success - error are functions
//axios.interceptors.response.use(success, error)
axios.interceptors.response.use(null, (error) => {
   //expected error - in 400 range
   const expectedError =
      error.response && error.response.status >= 400 && error.status < 500;
   if (expectedError) {
      return Promise.reject(error.response);
   } else {
      //Znaci ovaj mehanizam omogucava custom logiku za neocekivane greske
      //Moze se npr ugraditi funkcionalnost logiranja gresaka i sl... koju onda ne trebamo
      //implementirati u svakom try-catch bloku
      // if (error.response && error.response.status !== 400) {
      //    console.log('axios.interceptors.error', error.response);
      //    if (error.response.status === 401) {
      //       console.log('error.response.status === 401');
      //       //window.location = `${process.env.PUBLIC_URL}/#/logout`;
      //    }
      //    if (error.response.status >= 500) {
      //       console.log('error.response.status === 500');
      //    }
      // }
      // if (error.response && error.response.status === 400) {
      //    localStorage.setObject('error', error.response);
      //    window.location = `${process.env.PUBLIC_URL}/#/error`;
      // }
   }
   //to pass control to catch() block we need to return a rejected Promise with error included
   return Promise.reject(error.response);
});

const apiKey =
   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiJkMzY2ZDE5Ni0yOTk4LTQ3ZTYtODlhZC1hZjA2OTUyM2U5YmQiLCJ1bmlxdWVfbmFtZSI6IkJ1c2luZXNzIiwiZmFtaWx5X25hbWUiOiJDZW50cmFsIiwiZ2l2ZW5fbmFtZSI6IkJjQXBpLktvcmlzbmlrIiwiZW1haWwiOiIiLCJyb2xlIjoiQmNSb2xhIiwibmJmIjoxNTk4Mjc2NzQzLCJleHAiOjE1OTg4ODE1NDMsImlhdCI6MTU5ODI3Njc0M30.uxQgUZtlGTz1WJDVT4u9Ir-mmYONOp44y5qEQYHnFIs';
//axios.defaults.headers.common['Authorization'] = `${apiKey}`;
axios.defaults.headers.common['api-key'] = `${apiKey}`;

const exportObj = {
   get: axios.get,
   post: axios.post,
   put: axios.put,
   delete: axios.delete
};
export default exportObj;
