import React from 'react';
import logo from '../resources/Algebra-logo.png';
import NavigationLink from './navigation-link';
import { navbarAnimation } from './../animations/navbar-animation';
import FramerMotionContainer from './framer-motion-container';
import { getUser } from './../services/user';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

const Navbar = (props) => {
   const loginData = getUser() ? (
      <div>
         <span className='mr-3'>{getUser()}</span>
         <NavLink to={`/logout`} style={{ color: '#fff' }}>
            <FontAwesomeIcon icon={faSignOutAlt} />
         </NavLink>
      </div>
   ) : (
      ''
   );
   const { windowDimenion } = useSelector((state) => state.biblioteka);
   return (
      <FramerMotionContainer
         className='navbar'
         variants={navbarAnimation}
         style={{ height: windowDimenion.height <= 900 ? '4rem' : '7rem' }}
      >
         <div className='logo' style={{ width: '11rem' }}>
            <img
               src={logo}
               alt='algebrin logo'
               style={{ height: '1.4rem' }}
               className='mr-2'
            />
            <span
               style={{
                  color: '#c30e60',
                  textTransform: 'uppercase',
                  fontSize: '1.8rem'
               }}
            >
               Knjižnica
            </span>
         </div>
         {getUser() && (
            <div className='nav'>
               <NavigationLink to='/homepage' text='Pretraga' />
               <NavigationLink to='/packages' text='Paketi' />
            </div>
         )}
         <div className='user' style={{ width: '180px', textAlign: 'right' }}>
            {loginData}
         </div>
      </FramerMotionContainer>
   );
};

export default Navbar;
