import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { v4 as uuid } from 'uuid';
import Pagination from './pagination';
import { paginate } from './../services/paginate';
import FramerMotionContainer from './framer-motion-container';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setOdabraniStudent } from './../store/biblioteka';
import { paginationAnimation } from '../animations/search-result-animation';

const SearchResult = ({ data }) => {
   const [currentPage, setCurrentPage] = useState(1);
   const history = useHistory();
   const dispatch = useDispatch();

   const { windowDimenion } = useSelector((state) => state.biblioteka);

   const pageSize = windowDimenion.height <= 900 ? 6 : 10;

   const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
   };

   const students = paginate(data, currentPage, pageSize);

   const handleOnStudentClick = (student) => {
      dispatch(setOdabraniStudent(student));
      history.push(`/details/${student.id}`);
   };

   return (
      <div className='search-result'>
         <table className='table table-striped'>
            <motion.thead
               initial={{ opacity: 0, x: 30 }}
               animate={{ opacity: 1, x: 0 }}
               transition={{ duration: 0.2 }}
            >
               <tr>
                  <th>Ime</th>
                  <th>Prezime</th>
                  <th>OIB</th>
                  <th>JMBAG</th>
               </tr>
            </motion.thead>
            <tbody>
               {students.map((d, i) => (
                  <motion.tr
                     key={uuid()}
                     initial={{ opacity: 0, x: -30 }}
                     animate={{ opacity: 1, x: 0 }}
                     transition={{
                        delay: i * 0.05,
                        ease: [1, 0.01, 0.49, 1.05],
                        stiffness: 50
                     }}
                     whileHover={{
                        scaleX: [1, 1.05, 1]
                        // textShadow: '.1rem .1rem .1rem #333'
                     }}
                     onClick={() => {
                        handleOnStudentClick(d);
                     }}
                  >
                     <td>{d.ime}</td>
                     <td>{d.prezime}</td>
                     <td>{d.oib ? d.oib : '-'}</td>
                     <td>{d.jmbag ? d.jmbag : '-'}</td>
                  </motion.tr>
               ))}
            </tbody>
            <tfoot>
               <tr>
                  <td colSpan='4'>
                     <FramerMotionContainer
                        variants={paginationAnimation}
                        style={{ width: '90rem', overflow: 'hidden' }}
                     >
                        <Pagination
                           onPageChange={handlePageChange}
                           itemsCount={data.length}
                           pageSize={pageSize}
                           currentPage={currentPage}
                        />
                     </FramerMotionContainer>
                  </td>
               </tr>
            </tfoot>
         </table>
      </div>
   );
};

export default SearchResult;
