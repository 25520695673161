import {
    WarningOutlined,
    SyncOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';

const textStyle = {
    color: '#333',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '1.2rem'
};

const iconStyle = {
    color: '#333',
    textAlign: 'center',
    fontSize: '2.5rem'
};

const smileStyle = {
    ...iconStyle,
    color: '#fff'
};

const warningStyle = {
    ...iconStyle,
    color: '#fff'
};

const successTextStyle = {
    ...textStyle,
    color: '#fff'
};

const warningTextStyle = {
    ...textStyle,
    color: '#fff'
};

export const spiningLoader = (text) => (
    <span>
        <div style={iconStyle}>
            <SyncOutlined spin />
        </div>
        <div style={textStyle}>{text}</div>
    </span>
);

export const successSmile = (text) => (
    <span>
        <div style={smileStyle}>
            <CheckCircleOutlined />
        </div>
        <div style={successTextStyle}>{text}</div>
    </span>
);

export const warningSign = (text) => (
    <span>
        <div style={warningStyle}>
            <WarningOutlined />
        </div>
        <div style={warningTextStyle}>{text}</div>
    </span>
);
