import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { loadPackages } from '../store/biblioteka';
import { setSelectedPackage } from './../store/biblioteka';
import { motion } from 'framer-motion';

const ShowPackages = ({ onAdd, onDelete, editMode }) => {
   const { selectedCategory, selectedPackage, paketi } = useSelector(
      (state) => state.biblioteka
   );

   const dispatch = useDispatch();

   useEffect(() => {
      if (selectedCategory)
         dispatch(loadPackages(selectedCategory)).then((_paketi) => {
            dispatch(
               setSelectedPackage(_paketi.length > 0 ? _paketi[0].id : '')
            );
         });
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (selectedCategory)
         dispatch(loadPackages(selectedCategory)).then((_paketi) => {
            dispatch(
               setSelectedPackage(_paketi.length > 0 ? _paketi[0].id : '')
            );
         });
   }, [selectedCategory]); // eslint-disable-line react-hooks/exhaustive-deps

   const handleSelectPaket = ({ target: ddl }) => {
      dispatch(setSelectedPackage(+ddl.value));
   };

   return (
      <motion.div
         className='form-group'
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ delay: 0.5, duration: 1 }}
      >
         <div className='category-container'>
            <label htmlFor='paketi'>Paketi</label>
            {editMode && (
               <div className='category-buttons'>
                  <span className='add mr-2' onClick={onAdd}>
                     <FontAwesomeIcon
                        icon={faPlusSquare}
                        style={{
                           color: '#6363e0',
                           fontSize: '2rem'
                        }}
                     />
                  </span>

                  {paketi.length > 0 && (
                     <span className='remove' onClick={onDelete}>
                        <FontAwesomeIcon
                           icon={faMinusSquare}
                           style={{ color: '#f00', fontSize: '2rem' }}
                        />
                     </span>
                  )}
               </div>
            )}
         </div>
         <select
            className='form-control'
            id='paketi'
            onChange={handleSelectPaket}
            value={selectedPackage}
         >
            {paketi.map((p) => (
               <option key={p.id} value={p.id}>
                  {p.naziv}
               </option>
            ))}
         </select>
      </motion.div>
   );
};

export default ShowPackages;
