import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setStanjeKnjige } from '../store/biblioteka';
import { v4 as uuid } from 'uuid';
import { getNazivKnjige } from '../services/api';
import { stanjeKnjige } from '../services/common-data';

const ConfirmZaduziRazduzi = ({ inventarniBroj, stanje }) => {
    const dispatch = useDispatch();
    const [puniNazivKnjige, setPuniNazivKnjige] = useState('-');
    const [selectedStanjeKnjige, setSelectedStanjeKnjige] = useState(
        stanje ? stanje : 'Nova'
    );

    useEffect(() => {
        nazivKnjige();
        return () => {
            dispatch(setStanjeKnjige(stanjeKnjige[0]));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const nazivKnjige = async () => {
        try {
            const { data: result } = await getNazivKnjige(inventarniBroj);
            setPuniNazivKnjige(result.naslov ? result.naslov : '-');
        } catch (ex) {
            console.log('error', ex.data);
            return '-';
        }
    };

    const handleStanjeKnjigeChange = ({ target: ddl }) => {
        setSelectedStanjeKnjige(ddl.value);
        dispatch(setStanjeKnjige(ddl.value));
    };

    return (
        <table className='table table-sm' style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td
                        style={{ textAlign: 'left', width: '12rem' }}
                        className='align-middle'
                    >
                        Inventarni broj
                    </td>
                    <td style={{ textAlign: 'left' }} className='align-middle'>
                        {inventarniBroj}
                    </td>
                </tr>
                <tr>
                    <td style={{ textAlign: 'left' }} className='align-middle'>
                        Naslov
                    </td>
                    <td style={{ textAlign: 'left' }} className='align-middle'>
                        <div
                            style={{
                                width: '43rem',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {puniNazivKnjige}
                        </div>
                    </td>
                </tr>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                    <td style={{ textAlign: 'left' }} className='align-middle'>
                        Stanje
                    </td>
                    <td style={{ textAlign: 'left' }} className='align-middle'>
                        <select
                            onChange={handleStanjeKnjigeChange}
                            value={selectedStanjeKnjige}
                        >
                            {stanjeKnjige.map((k) => (
                                <option key={uuid()}>{k}</option>
                            ))}
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default ConfirmZaduziRazduzi;
