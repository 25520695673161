export const h1Variants = {
    hidden: {
        opacity: 0,
        x: 100
    },
    show: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1,
            type: 'spring'
        }
    }
};
export const tableVariants = {
    hidden: {
        opacity: 0,
        x: -50
    },
    show: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1,
            type: 'spring'
        }
    }
};
