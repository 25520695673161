import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import FramerMotionContainer from './../components/framer-motion-container';
import AddBookToPackage from '../components/add-book-to-package';
import ConfirmDeleteBookFromPackage from '../components/confirm-delete-book-from-package';
import AddPackage from '../components/add-package';
import ConfirmDeletePackage from '../components/confirm-delete-package';
import AddCategory from '../components/add-category';
import ConfirmDeleteCategory from '../components/confirm-delete-category';
import ShowBooks from '../components/package-show-books';
import ShowPackages from '../components/package-show-packages';
import ShowCategories from './../components/package-show-categories';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
   setSelectedCategory,
   setSelectedPackage,
   createCategory,
   deleteCategory,
   createPackage,
   deletePackage,
   createBook,
   deleteBook,
   resetPackages
} from './../store/biblioteka';
import { spiningLoader, successSmile } from '../services/toast';
import { warningSign } from './../services/toast';

const PackagesPage = (props) => {
   const {
      selectedCategory,
      selectedPackage,
      selectedBook,
      kategorije,
      paketi,
      knjige
   } = useSelector((state) => state.biblioteka);

   const [showAddKategorija, setShowAddKategorija] = useState(false);
   const [showAddPaket, setShowAddPaket] = useState(false);
   const [showAddKnjiga, setShowAddKnjiga] = useState(false);

   const [showConfirmDeleteKategorija, setShowConfirmDeleteKategorija] =
      useState(false);
   const [showConfirmDeletePaket, setShowConfirmDeletePaket] = useState(false);
   const [showConfirmDeleteKnjiga, setShowConfirmDeleteKnjiga] =
      useState(false);

   const [, setUpdatedKategorije] = useState(kategorije);

   const dispatch = useDispatch();
   const history = useHistory();

   useEffect(() => {
      document.title = 'Paketi';
      return () => {
         dispatch(resetPackages());
      };
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      setUpdatedKategorije(kategorije);
   }, [kategorije]); // eslint-disable-line react-hooks/exhaustive-deps

   /*
         KATEGORIJE
   */
   const handleAddKategorija = () => {
      setShowAddKategorija(true);
   };

   const categoryExsists = (categoryName) =>
      kategorije.find(
         (k) =>
            k.naziv.trim().toLowerCase() === categoryName.trim().toLowerCase()
      );

   const handleOnAddKategorija = async (naziv) => {
      if (categoryExsists(naziv)) {
         toast.error(warningSign('Kategorija s istim nazivom već postoji'));
         return;
      }
      // toast(spiningLoader('Kreiranje kategorije...'));
      setShowAddKategorija(false);
      dispatch(createCategory(naziv.trim())).then(({ data: addedCategory }) => {
         dispatch(setSelectedCategory(addedCategory.id));
         toast.info(
            successSmile(
               `Kategorija [ ${addedCategory.naziv} ] uspješno kreirana :)`
            )
         );
      });
      //window.location.reload();
   };

   const handleOnCancelAddingKategorija = () => {
      setShowAddKategorija(false);
   };

   const handleDeleteKategorija = () => {
      setShowConfirmDeleteKategorija(true);
   };

   const handleOnDeleteKategorija = async () => {
      try {
         // toast(spiningLoader('Brisanje kategorije...'));
         setShowConfirmDeleteKategorija(false);
         dispatch(deleteCategory(selectedCategory)).then(
            ({ response, updatedCategories }) => {
               const { statusCode } = response;
               if (statusCode === 200)
                  toast.info(successSmile('Uspješno obrisana kategorija'));
               else toast.warning(successSmile('Kategorija nije obrisana :/'));

               // ako su obrisane sve kategorije
               if (updatedCategories.length === 0) {
                  dispatch(setSelectedCategory(''));
                  dispatch(resetPackages());
                  return;
               }

               dispatch(
                  setSelectedCategory(
                     updatedCategories.length > 0 ? updatedCategories[0].id : ''
                  )
               );
            }
         );
      } catch (ex) {
         toast.error('Greška :(', {
            onClose: () => {
               localStorage.setObject('error', ex);
               history.push(`/error`);
            }
         });
      }
   };

   const handleCancelDeleteKategorija = () => {
      setShowConfirmDeleteKategorija(false);
   };

   /*
         PAKETI
   */

   const handleAddPaket = () => {
      setShowAddPaket(true);
   };

   const packageExsists = (packageName) =>
      paketi.find(
         (p) =>
            p.naziv.trim().toLowerCase() === packageName.trim().toLowerCase()
      );

   const handleOnAddPaket = async (nazivPaketa) => {
      if (packageExsists(nazivPaketa)) {
         toast.error(warningSign('Paket s istim nazivom već postoji'));
         return;
      }
      // toast(spiningLoader('Kreiranje paketa...'));
      setShowAddPaket(false);
      dispatch(createPackage(nazivPaketa.trim(), selectedCategory)).then(
         ({ data: addedPackage }) => {
            dispatch(setSelectedPackage(addedPackage.id));
            toast.info(
               successSmile(
                  `Paket [ ${addedPackage.naziv} ] uspješno kreiran :)`
               )
            );
         }
      );
   };

   const handleOnCancelAddingPaket = () => {
      setShowAddPaket(false);
   };

   const handleDeletePaket = () => {
      setShowConfirmDeletePaket(true);
   };

   const handleOnDeletePaket = async () => {
      try {
         // toast(spiningLoader('Brisanje paketa...'));
         setShowConfirmDeletePaket(false);
         dispatch(deletePackage(selectedPackage)).then(
            ({ response, updatedPackages }) => {
               const { statusCode } = response;
               if (statusCode === 200)
                  toast.info(successSmile('Uspješno obrisan paket'));
               else toast.warning(successSmile('Paket nije obrisan :/'));

               const selectedPackage =
                  updatedPackages.length > 0 ? updatedPackages[0].id : '';
               dispatch(setSelectedPackage(selectedPackage));
            }
         );
      } catch (ex) {
         toast.error('Greška :(', {
            onClose: () => {
               localStorage.setObject('error', ex);
               history.push(`/error`);
            }
         });
      }
   };

   const handleCancelDeletePaket = () => {
      setShowConfirmDeletePaket(false);
   };

   /*
         KNJIGA
   */

   const handleAddKnjiga = () => {
      setShowAddKnjiga(true);
   };

   const bookExsists = (inventarniBroj) =>
      knjige.find(
         (k) =>
            k.inventarniBroj.trim().toLowerCase() ===
            inventarniBroj.trim().toLowerCase()
      );

   const handleOnAddKnjiga = async (naziv, inventarniBroj) => {
      if (bookExsists(inventarniBroj)) {
         toast.error(
            warningSign('Knjiga s istim inventarnim brojem je već dodana')
         );
         return;
      }

      // toast(spiningLoader('Kreiranje knjige...'));
      setShowAddKnjiga(false);
      dispatch(createBook(naziv.trim(), inventarniBroj)).then((addedBook) => {
         toast.info(successSmile(`Knjiga uspješno dodana :)`));
      });
   };

   const handleOnCancelAddingKnjiga = () => {
      setShowAddKnjiga(false);
   };

   const handleDeleteKnjiga = () => {
      setShowConfirmDeleteKnjiga(true);
   };

   const handleOnDeleteKnjiga = async () => {
      try {
         // toast(spiningLoader('Brisanje kategorije...'));
         setShowConfirmDeleteKnjiga(false);
         dispatch(deleteBook(selectedBook)).then((response) => {
            const { statusCode } = response;
            if (statusCode === 200)
               toast.info(successSmile('Knjiga uspješno obrisana'));
            else toast.warning(successSmile('Knjiga nije obrisana'));
         });
      } catch (ex) {
         toast.error('Greška :(', {
            onClose: () => {
               localStorage.setObject('error', ex);
               history.push(`/error`);
            }
         });
      }
   };

   const handleCancelDeleteKnjiga = () => {
      setShowConfirmDeleteKnjiga(false);
   };

   return (
      <div className='package-pages center'>
         <AnimatePresence>
            {showAddKategorija && (
               <AddCategory
                  onOk={handleOnAddKategorija}
                  onCancel={handleOnCancelAddingKategorija}
               />
            )}

            {showAddPaket && (
               <AddPackage
                  onOk={handleOnAddPaket}
                  onCancel={handleOnCancelAddingPaket}
                  selectedKategorija={selectedCategory}
               />
            )}

            {showAddKnjiga && (
               <AddBookToPackage
                  onOk={handleOnAddKnjiga}
                  onCancel={handleOnCancelAddingKnjiga}
               />
            )}

            {showConfirmDeleteKategorija && (
               <ConfirmDeleteCategory
                  onOk={handleOnDeleteKategorija}
                  onCancel={handleCancelDeleteKategorija}
                  selectedKategorija={selectedCategory}
               />
            )}

            {showConfirmDeletePaket && (
               <ConfirmDeletePackage
                  onOk={handleOnDeletePaket}
                  onCancel={handleCancelDeletePaket}
               />
            )}

            {showConfirmDeleteKnjiga && (
               <ConfirmDeleteBookFromPackage
                  onOk={handleOnDeleteKnjiga}
                  onCancel={handleCancelDeleteKnjiga}
               />
            )}
         </AnimatePresence>
         <FramerMotionContainer className='content-container'>
            <motion.h1
               initial={{ opacity: 0, x: 50 }}
               animate={{ opacity: 1, x: 0 }}
               transition={{ duration: 0.5 }}
            >
               Pregled i uređivanje gotovih paketa
            </motion.h1>
            <hr style={{ margin: '2rem auto 4rem' }} />
            <div className='row'>
               <div className='col'>
                  <ShowCategories
                     editMode
                     onAdd={handleAddKategorija}
                     onDelete={handleDeleteKategorija}
                  />
               </div>
               <div className='col'>
                  <ShowPackages
                     editMode
                     onAdd={handleAddPaket}
                     onDelete={handleDeletePaket}
                  />
               </div>
            </div>
            <div className='row mt-3'>
               <div className='col'>
                  <ShowBooks
                     editMode={selectedPackage}
                     onAdd={handleAddKnjiga}
                     onDelete={handleDeleteKnjiga}
                  />
               </div>
            </div>
         </FramerMotionContainer>
      </div>
   );
};

export default PackagesPage;
