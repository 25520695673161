export const paginationAnimation = {
    hidden: {
        x: -20,
        opacity: 0
    },
    show: {
        x: 0,
        opacity: 1,
        transition: {
            delay: 0.2,
            duration: 0.5,
            type: 'spring',
            stiffness: 200
        }
    }
};
