import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { zaduziKnjigu } from '../store/biblioteka';
import ConfirmZaduziRazduzi from './confirm-zaduzi-razduzi';
import CustomAlert from './custom-alert';
import { AnimatePresence } from 'framer-motion';
import FramerMotionContainer from './framer-motion-container';
import { getUser } from '../services/user';

const variants = {
    hidden: {
        opacity: 0,
        y: -50
    },
    show: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1.5,
            type: 'spring'
        }
    }
};

const AddBook = (props) => {
    const [inventarniBroj, setInventarniBroj] = useState('');
    const [showRentBookConfirm, setShowRentBookConfirm] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const { odabraniStudent, zaduzeneKnjige, stanjeKnjige } = useSelector(
        (state) => state.biblioteka
    );

    const inputRef = useRef(null);

    const dispatch = useDispatch();
    const handleDodajSkeniranuKnjigu = (e) => {
        const noviBarkod = inventarniBroj.trim();

        const knjigaVecZaduzena = zaduzeneKnjige.find(
            (k) => k.inventarniBroj === noviBarkod
        );

        if (knjigaVecZaduzena) {
            setShowAlert(true);
            return;
        }
        setShowRentBookConfirm(true);
    };

    const handleOk = () => {
        const knjiga = {
            id: odabraniStudent.id,
            korisnik: getUser(),
            inventarniBroj,
            stanjeKnjige
        };

        dispatch(zaduziKnjigu(knjiga));
        setShowRentBookConfirm(false);
        setInventarniBroj('');
        inputRef.current.focus();
    };

    const handleAlertOk = () => {
        setShowAlert(false);
        setInventarniBroj('');
        inputRef.current.focus();
    };

    const handleCancel = () => {
        setShowRentBookConfirm(false);
        setInventarniBroj('');
        inputRef.current.focus();
    };

    return (
        <>
            <AnimatePresence>
                {showRentBookConfirm && (
                    <CustomAlert
                        title='Zaduživanje knjige'
                        btnOk='Zaduži'
                        btnCancel='Odustani'
                        onOk={handleOk}
                        onCancel={handleCancel}
                    >
                        <ConfirmZaduziRazduzi inventarniBroj={inventarniBroj} />
                    </CustomAlert>
                )}
                {showAlert && (
                    <CustomAlert
                        title='Upozorenje'
                        btnOk='Ok'
                        onOk={handleAlertOk}
                        alertMode={true}
                    >
                        {`Knjiga sa inventarnim brojem ${inventarniBroj} je več zadužena`}
                    </CustomAlert>
                )}
            </AnimatePresence>
            <div className='add-book'>
                <FramerMotionContainer variants={variants}>
                    <div>
                        <label htmlFor='addBook'>Inventarni broj</label>
                        <input
                            id='addBook'
                            ref={inputRef}
                            type='text'
                            autoFocus={true}
                            autoComplete='off'
                            className='form-control'
                            placeholder='Zadužite knjigu tako da skenirate ili unesite inventarni broj...'
                            onChange={(e) => {
                                setInventarniBroj(
                                    e.target.value.replace("'", '-')
                                );
                            }}
                            value={inventarniBroj}
                            style={{ width: '100%' }}
                        />
                        <button
                            disabled={inventarniBroj.length === 0}
                            className='btn btn-dark btn-lg mt-3'
                            onClick={handleDodajSkeniranuKnjigu}
                            onFocus={handleDodajSkeniranuKnjigu}
                        >
                            Zaduži knjigu
                        </button>
                    </div>
                </FramerMotionContainer>
            </div>
        </>
    );
};

export default AddBook;
