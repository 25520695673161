import React from 'react';
import logo from '../resources/Algebra-logo.png';
import FramerMotionContainer from './framer-motion-container';
import { footerAnimation } from '../animations/footer-animation';
import { url } from '../services/api';
import { useSelector } from 'react-redux';

const Footer = (props) => {
   const { windowDimenion } = useSelector((state) => state.biblioteka);
   return (
      <FramerMotionContainer
         className='footer'
         variants={footerAnimation}
         style={{ height: windowDimenion.height <= 900 ? '4rem' : '7rem' }}
      >
         <img src={logo} alt='algebrin logo' />
         lgebra knjižnica <span style={{ color: '#111' }}>sql</span>
         {url.includes('test') && (
            <span className='ml-3' style={{ color: 'yellow' }}>
               test DB
            </span>
         )}
      </FramerMotionContainer>
   );
};

export default Footer;
