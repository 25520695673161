export const idleTimeout = 15 * 60 * 1000;

export const getUser = () => {
   return localStorage.getItem('korisnickoIme');
};

export const idleTimeHasExpired = () => {
   /*
      app.jsx
      provjeravam kod učitavanja da li je istekao idleTime i ako jest
      radim redirekt na logout
      U tom trenutku brišem timestamp kako kod redirekta ne bi ušao
      u loop
   */
   if (!localStorage.getItem('timestamp')) return false;

   const lastActionTimestamp = Number(localStorage.getItem('timestamp'));
   const now = new Date().getTime();
   const timespan = now - lastActionTimestamp;
   return timespan > idleTimeout;
};
