import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CustomAlert from './custom-alert';
const AddPackage = ({ onOk, onCancel }) => {
   const [naziv, setNaziv] = useState('');

   const { selectedCategory, kategorije } = useSelector(
      (state) => state.biblioteka
   );

   const getNazivKategorije = (id) => {
      const _kategorija = kategorije.find((k) => k.id === id);
      return _kategorija ? _kategorija.naziv : '';
   };

   return (
      <CustomAlert
         title={`Dodavanje paketa u kategoriju ${getNazivKategorije(
            selectedCategory
         )}`}
         onOk={() => onOk(naziv)}
         onCancel={onCancel}
         btnOk='Dodaj'
         btnCancel='Odustani'
         btnOkDisabled={naziv.length === 0}
      >
         <div className='form-group'>
            <label htmlFor=''>Naziv paketa</label>
            <input
               autoFocus
               type='text'
               className='form-control'
               style={{ fontSize: '1.6rem' }}
               onChange={({ target: input }) => {
                  setNaziv(input.value);
               }}
            />
         </div>
      </CustomAlert>
   );
};

export default AddPackage;
