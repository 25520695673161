import React, { useEffect, useState } from 'react';
import FramerMotionContainer from './../components/framer-motion-container';
import Search from '../components/search';
import { getStudenti } from '../services/api';
import SearchResult from '../components/search-result';
import { motion, useAnimation } from 'framer-motion';
import {
    searchAnimation,
    searchResultAnimation
} from './../services/search-animation';
import AlgebraLogo from '../components/algebra-logo';

const variantsNoResult = {
    hidden: {
        opacity: 0,
        x: 30
    },
    show: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.5,
            type: 'spring',
            stiffness: 200
        }
    }
};

const Homepage = (props) => {
    const [searchWhat, setSearchWhat] = useState('');
    const [responseData, setResponseData] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);

    useEffect(() => {
        document.title = 'Pretraga studenata';
        return () => {};
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        controls.start('center');
        const fetchData = async () => {
            controls.start('moveUp');
            try {
                setIsLoadingData(true);
                const { data: response } = await getStudenti(searchWhat);
                setResponseData(response);
                //getContentContainerHeight();
            } catch (error) {
                if (error.data.response === '404') setResponseData([]);
            }
            setIsLoadingData(false);
        };
        if (searchWhat.trim() !== '') fetchData();
        else setResponseData([]);
    }, [searchWhat]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOnSearch = (searchValue) => {
        setSearchWhat(searchValue);
    };

    const controls = useAnimation();

    return (
        <div className='homepage'>
            <AlgebraLogo />
            <motion.div
                className='search-container'
                variants={searchAnimation}
                animate={controls}
            >
                <Search
                    searchOnTyping={true}
                    onSearch={handleOnSearch}
                    isLoadingData={isLoadingData}
                />
            </motion.div>
            {searchWhat.trim() !== '' && (
                <FramerMotionContainer
                    className='content-container'
                    style={{ maxHeight: '71rem', minHeight: '10rem' }}
                    variants={searchResultAnimation}
                >
                    <h1>Rezultati pretrage</h1>
                    {responseData.length === 0 &&
                        searchWhat.length !== 0 &&
                        !isLoadingData && (
                            <FramerMotionContainer
                                variants={variantsNoResult}
                                style={{
                                    fontSize: '1.6rem',
                                    color: '#333',
                                    fontWeight: 700
                                }}
                            >
                                <span>
                                    Za uneseni pojam{' '}
                                    <span
                                        style={{
                                            color: '#fff',
                                            backgroundColor: '#c30e60',
                                            padding: '0 .5rem'
                                        }}
                                    >
                                        {searchWhat}
                                    </span>{' '}
                                    nema rezultata...
                                </span>
                            </FramerMotionContainer>
                        )}
                    {responseData.length > 0 && (
                        <SearchResult data={responseData} />
                    )}
                </FramerMotionContainer>
            )}
        </div>
    );
};

export default Homepage;
