import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const NavigationLink = ({ to, text }) => {
   const { pathname } = useLocation();

   const url = `${process.env.PUBLIC_URL}`;

   /*   Ako sam na putanji koja ima ID, maknem taj ID za switch provjeru  */
   const arr = pathname.split('/');
   const nemaID = isNaN(Number(arr[arr.length - 1]));
   if (!nemaID) arr.pop();
   const putanja = nemaID ? pathname : arr.join('/');

   switch (putanja) {
      case `${url}/add-author`:
         break;

      default:
         break;
   }

   return (
      <div className='navigation-link'>
         <NavLink
            //style={{ color: pathname === to ? '#c30e60' : '#fff' }}
            to={to}
            style={{
               color: pathname === to ? '#e1700e' : '#fff',
               textShadow: pathname === to ? '0 0 .25rem #e1700e' : 'none'
            }}
         >
            {text}
         </NavLink>
         {/* <motion.div
                className='background'
                initial={{ opacity: 0 }}
                animate={{
                    opacity: pathname === to ? 1 : 0,
                    height: pathname === to ? 43 : 0
                }}
                transition={{ duration: 0.25 }}
            ></motion.div> */}
      </div>
   );
};

export default NavigationLink;
