import React from 'react';
import { Route } from 'react-router-dom';
import { getUser } from './../services/user';

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
   return (
      <Route
         {...rest}
         render={(props) => {
            if (!getUser())
               //return <Redirect to={`${process.env.PUBLIC_URL}/login`} />;
               window.location = `${process.env.PUBLIC_URL}/#/login`;
            return Component ? <Component {...props} /> : render(props);
         }}
      />
   );
};

export default ProtectedRoute;
