import React, { useEffect } from 'react';
import FramerMotionContainer from './../components/framer-motion-container';

const ErrorPage = (props) => {
   const error = localStorage.getObject('error');
   if (!error) window.location = '/homepage';

   useEffect(() => {
      document.title = `Error page`;
      return () => {
         localStorage.removeItem('error');
      };
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div className='error-page'>
         <FramerMotionContainer className='content-container'>
            <h1>Error</h1>
            <hr />
            <p style={{ fontWeight: 700, color: '#f00' }}>
               {error.data.response}
            </p>
            <p style={{ fontWeight: 700 }}>{error.data.responseDescription}</p>
            <p>{error.config.url}</p>
            <p>{error.config.data}</p>
         </FramerMotionContainer>
      </div>
   );
};

export default ErrorPage;
