import React from 'react';
import { useSelector } from 'react-redux';
import CustomAlert from './custom-alert';

const ConfirmDeleteCategory = ({ onOk, onCancel }) => {
   const { kategorije, selectedCategory } = useSelector(
      (state) => state.biblioteka
   );

   const getNazivKategorije = (id) => {
      const category = kategorije.find((k) => k.id === id);
      return category ? category.naziv : '';
   };

   return (
      <>
         <CustomAlert
            title='Potvrdite brisanje'
            onOk={onOk}
            onCancel={onCancel}
            btnOk='Obriši'
            btnCancel='Odustani'
         >
            Obristi kategoriju{' '}
            <span style={{ fontWeight: 'bold' }}>
               {getNazivKategorije(selectedCategory)}
            </span>
            ?
         </CustomAlert>
      </>
   );
};

export default ConfirmDeleteCategory;
