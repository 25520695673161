import { Component } from 'react';
import Joi from 'joi-browser';
import Input from './input';

class Form extends Component {
    state = {
        validationData: {},
        errors: {}
    };

    /*
      Validacija svih svojstava validationData objekta, cijele forme...
      onSubmit()
   */
    validateForm = () => {
        //validiraj
        const objectToValidate = this.state.validationData;
        const options = {
            abortEarly: false
        };
        const result = Joi.validate(objectToValidate, this.schema, options);

        //ako je sve ok...
        if (!result.error) return null;

        //dodijeli greške errors objektu
        const errors = {};
        for (const item of result.error.details) {
            errors[item.path[0]] = item.message;
        }

        return errors;
    };

    /*
    Validacija jednog od svojstava validationData objekta
 */
    validateProperty = ({ name, value }) => {
        //console.log('validateProperty', name, value);
        //validira se samo jedno svojstvo validationData objekta
        const objectToValidate = { [name]: value };
        //shemu uzimam sa postojece sheme za validationData objekt
        const schema = { [name]: this.schema[name] };

        const result = Joi.validate(objectToValidate, schema);
        return result.error ? result.error.details[0].message : null;
    };

    handleFormSubmit = (e) => {
        e.preventDefault();

        const errors = this.validateForm();
        //console.log('errors', errors);
        this.setState({ errors: errors || {} });

        this.doSubmit();
    };

    handleInputChange = ({ currentTarget: input }) => {
        //console.log('handleInputChange', input);
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        const validationData = { ...this.state.validationData };
        validationData[input.id] = input.value;
        this.setState({ validationData, errors });
    };

    renderInput = (name, label, attributes, required) => {
        const { validationData, errors } = this.state;
        return (
            <Input
                id={name}
                name={name}
                label={label}
                {...attributes}
                value={validationData[name]}
                error={errors[name]}
                onChange={this.handleInputChange}
                required={required}
            />
        );
    };

    renderSubmitButton = (label, disabled) => {
        return (
            <button
                className='btn btn-info btn-sm mr-1 mt-4 form-control'
                style={{
                    fontWeight: 500,
                    background: '#c30e60',
                    border: 'none',
                    padding: '.5rem 1rem',
                    height: '4rem'
                }}
                type='submit'
                disabled={disabled}
            >
                {label}
            </button>
        );
    };
}

export default Form;
