import {
   getKategorije,
   getPaketi,
   getKnjige,
   createKategorija,
   deleteKategorija,
   createPaket,
   deletePaket,
   createKnjiga,
   deleteKnjiga
} from './../services/api-packages';
import {
   getStudenti,
   getZaduzeneKnjige,
   zaduziStudentuKnjigu,
   razduziStudentuKnjigu,
   zaduziPaketKnjiga
} from '../services/api';

const initState = {
   kategorije: [],
   paketi: [],
   knjige: [],
   studenti: [],
   zaduzeneKnjige: [],
   odabraniStudent: {},
   stanjeKnjige: 'Nova',
   selectedCategory: '',
   selectedPackage: '',
   selectedBook: '',
   windowDimenion: {}
};

export const createCategory = (naziv) => async (dispatch, getState) => {
   try {
      const { data: addedCategory } = await createKategorija(naziv);

      await dispatch(loadCategories());
      return Promise.resolve(addedCategory);
   } catch (ex) {
      if (ex && ex.response && ex.response.status === 400) {
         console.log('LOAD_CATEGORIES', ex.response.data);
      } else {
         console.log('biblioteka.js error');
      }
   }
};

export const deleteCategory = (categoryId) => async (dispatch, getState) => {
   try {
      const { data: response } = await deleteKategorija(categoryId);

      await dispatch(loadCategories());
      const updatedCategories = getState().biblioteka.kategorije;

      return Promise.resolve({
         response,
         updatedCategories
      });
   } catch (ex) {
      if (ex && ex.response && ex.response.status === 400) {
         console.log('LOAD_CATEGORIES', ex.response.data);
      } else {
         console.log('biblioteka.js error');
      }
   }
};

export const loadCategories = () => async (dispatch, getState) => {
   try {
      const { data: response } = await getKategorije();

      const payload = {
         kategorije: response.data
      };

      await dispatch({ type: 'LOAD_CATEGORIES', payload });
      return Promise.resolve(response.data);
   } catch (ex) {
      if (ex && ex.response && ex.response.status === 400) {
         console.log('LOAD_CATEGORIES', ex.response.data);
      } else {
         console.log('biblioteka.js error');
      }
   }
};

export const createPackage =
   (naziv, categoryId) => async (dispatch, getState) => {
      try {
         const { data: addedPackage } = await createPaket(naziv, categoryId);
         await dispatch(loadPackages(categoryId));
         return Promise.resolve(addedPackage);
      } catch (ex) {
         if (ex && ex.response && ex.response.status === 400) {
            console.log('LOAD_CATEGORIES', ex.response.data);
         } else {
            console.log('biblioteka.js error');
         }
      }
   };

export const deletePackage = (packageId) => async (dispatch, getState) => {
   try {
      const categoryId = getState().biblioteka.selectedCategory;

      const { data: response } = await deletePaket(packageId);
      await dispatch(loadPackages(categoryId));
      const updatedPackages = getState().biblioteka.paketi;

      return Promise.resolve({
         response,
         updatedPackages
      });
   } catch (ex) {
      if (ex && ex.response && ex.response.status === 400) {
         console.log('LOAD_CATEGORIES', ex.response.data);
      } else {
         console.log('biblioteka.js error');
      }
   }
};

export const loadPackages = (kategorijaId) => async (dispatch, getState) => {
   try {
      const { data: response } = await getPaketi(kategorijaId);

      const payload = {
         paketi: response.data
      };

      await dispatch({ type: 'LOAD_PACKAGES', payload });
      return Promise.resolve(response.data);
   } catch (ex) {
      if (ex && ex.response && ex.response.status === 400) {
         console.log('LOAD_PACKAGES', ex.response.data);
      } else {
         console.log('biblioteka.js error');
      }
   }
};

export const createBook =
   (naziv, inventarniBroj) => async (dispatch, getState) => {
      try {
         const packageId = getState().biblioteka.selectedPackage;
         const { data: addedBook } = await createKnjiga(
            naziv,
            inventarniBroj,
            packageId
         );

         await dispatch(loadBooks(packageId));
         return Promise.resolve(addedBook);
      } catch (ex) {
         if (ex && ex.response && ex.response.status === 400) {
            console.log('LOAD_CATEGORIES', ex.response.data);
         } else {
            console.log('biblioteka.js error');
         }
      }
   };

export const loadBooks = (packageId) => async (dispatch, getState) => {
   try {
      const { data: response } = await getKnjige(packageId);

      const payload = {
         knjige: response.data
      };

      await dispatch({ type: 'LOAD_BOOKS', payload });
   } catch (ex) {
      if (ex && ex.response && ex.response.status === 400) {
         console.log('LOAD_BOOKS', ex.response.data);
      } else {
         console.log('biblioteka.js error');
      }
   }
};

export const deleteBook = (bookId) => async (dispatch, getState) => {
   try {
      console.log('brisem knjigu', bookId);
      const { data: response } = await deleteKnjiga(bookId);
      console.log('response', response);
      const packageId = getState().biblioteka.selectedPackage;

      await dispatch(loadBooks(packageId));
      return Promise.resolve(response);
   } catch (ex) {
      console.log('ex', ex);
      if (ex && ex.response && ex.response.status === 400) {
         console.log('LOAD_CATEGORIES', ex.response.data);
      } else {
         console.log('biblioteka.js error');
      }
   }
};

export const traziStudente = (trazi) => async (dispatch, getState) => {
   try {
      const { data: studenti } = await getStudenti(trazi);

      const payload = {
         studenti
      };

      await dispatch({ type: 'TRAZI_STUDENTE', payload });
   } catch (ex) {
      if (ex && ex.response && ex.response.status === 400) {
         console.log('TRAZI_STUDENTE', ex.response.data);
      } else {
         console.log('biblioteka.js error');
      }
   }
};

export const ucitajZaduzeneKnjige = (id) => async (dispatch, getState) => {
   try {
      let payload = {};
      try {
         const { data: zaduzeneKnjige } = await getZaduzeneKnjige(id);

         payload = {
            zaduzeneKnjige
         };
      } catch (error) {
         payload = {
            zaduzeneKnjige: []
         };
      } finally {
         await dispatch({ type: 'UCITAJ_ZADUZENE_KNJIGE', payload });
      }
   } catch (ex) {
      if (ex && ex.response && ex.response.status === 400) {
         console.log('UCITAJ_ZADUZENE_KNJIGE', ex.response.data);
      } else {
         console.log('biblioteka.js error');
      }
   }
};

export const zaduziKnjigu = (knjiga) => async (dispatch, getState) => {
   try {
      await zaduziStudentuKnjigu(knjiga);
      await dispatch(ucitajZaduzeneKnjige(knjiga.id));

      await dispatch({ type: 'ZADUZI_KNJIGU' });
   } catch (ex) {
      if (ex && ex.response && ex.response.status === 400) {
         console.log('ZADUZI_KNJIGU', ex.response.data);
      } else {
         console.log('biblioteka.js error');
      }
   }
};

export const zaduziPaket =
   (studentId, knjige, korisnik) => async (dispatch, getState) => {
      try {
         await zaduziPaketKnjiga(studentId, knjige, korisnik);
         await dispatch(ucitajZaduzeneKnjige(studentId));

         await dispatch({ type: 'ZADUZI_PAKET_KNJIGA' });
      } catch (ex) {
         if (ex && ex.response && ex.response.status === 400) {
            console.log('ZADUZI_PAKET_KNJIGA', ex.response.data);
         } else {
            console.log('biblioteka.js error');
         }
      }
   };

export const razduziKnjigu = (knjiga) => async (dispatch, getState) => {
   try {
      await razduziStudentuKnjigu(knjiga);
      await dispatch(ucitajZaduzeneKnjige(knjiga.id));

      await dispatch({ type: 'RAZDUZI_KNJIGU' });
   } catch (ex) {
      if (ex && ex.response && ex.response.status === 400) {
         console.log('RAZDUZI_KNJIGU', ex.response.data);
      } else {
         console.log('biblioteka.js error');
      }
   }
};

export const obrisiZaduzeneKnjige = () => ({
   type: 'OBRISI_ZADUZENE_KNJIGE'
});

export const clearBooks = () => ({
   type: 'CLEAR_BOOKS'
});

export const resetPackages = () => ({
   type: 'RESET_PACKAGES'
});

export const setSelectedCategory = (selectedCategory) => ({
   type: 'SET_SELECTED_CATEGORY',
   payload: {
      selectedCategory
   }
});

export const setSelectedPackage = (selectedPackage) => ({
   type: 'SET_SELECTED_PACKAGE',
   payload: {
      selectedPackage
   }
});

export const setSelectedBook = (selectedBook) => ({
   type: 'SET_SELECTED_BOOK',
   payload: {
      selectedBook
   }
});

export const setOdabraniStudent = (odabraniStudent) => ({
   type: 'ODABRANI_STUDENT',
   payload: {
      odabraniStudent
   }
});

export const setStanjeKnjige = (stanjeKnjige) => ({
   type: 'SET_STANJE_KNJIGE',
   payload: {
      stanjeKnjige
   }
});

export const setWindowDimensions = (windowDimenion) => ({
   type: 'SET_WINDOW_DIMENSIONS',
   payload: {
      windowDimenion
   }
});

//  REDUCER
export default function reducer(state = initState, action) {
   switch (action.type) {
      case 'SET_WINDOW_DIMENSIONS':
         const { windowDimenion } = action.payload;
         return {
            ...state,
            windowDimenion
         };
      case 'ODABRANI_STUDENT':
         const { odabraniStudent } = action.payload;
         return {
            ...state,
            odabraniStudent
         };
      case 'LOAD_CATEGORIES':
         const { kategorije } = action.payload;
         return {
            ...state,
            kategorije
         };
      case 'LOAD_PACKAGES':
         const { paketi } = action.payload;
         return {
            ...state,
            paketi
         };
      case 'LOAD_BOOKS':
         const { knjige } = action.payload;
         return {
            ...state,
            knjige
         };
      case 'TRAZI_STUDENTE':
         const { studenti } = action.payload;
         return {
            ...state,
            studenti
         };
      case 'UCITAJ_ZADUZENE_KNJIGE':
         const { zaduzeneKnjige } = action.payload;
         return {
            ...state,
            zaduzeneKnjige
         };
      case 'OBRISI_ZADUZENE_KNJIGE':
         return {
            ...state,
            zaduzeneKnjige: []
         };
      case 'SET_STANJE_KNJIGE':
         const { stanjeKnjige } = action.payload;
         return {
            ...state,
            stanjeKnjige
         };
      case 'SET_SELECTED_CATEGORY':
         const { selectedCategory } = action.payload;
         return {
            ...state,
            selectedCategory
         };
      case 'SET_SELECTED_PACKAGE':
         const { selectedPackage } = action.payload;
         return {
            ...state,
            selectedPackage
         };
      case 'SET_SELECTED_BOOK':
         const { selectedBook } = action.payload;
         return {
            ...state,
            selectedBook
         };
      case 'RESET_PACKAGES':
         return {
            ...state,
            knjige: [],
            paketi: [],
            kategorije: [],
            selectedCategory: '',
            selectedPackage: '',
            selectedBook: ''
         };
      case 'CLEAR_BOOKS':
         return { ...state, knjige: [] };
      case 'ZADUZI_KNJIGU':
         return state;
      case 'ZADUZI_PAKET_KNJIGA':
         return state;
      case 'RAZDUZI_KNJIGU':
         return state;
      default:
         return state;
   }
}

//https://medium.com/collaborne-engineering/returning-promises-from-redux-action-creators-3035f34fa74b
