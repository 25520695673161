const animationSpeedShow = 0.3;
const stiffness = 150;
export const searchAnimation = {
    moveUp: {
        y: 120,
        transition: {
            duration: animationSpeedShow
        }
    },
    center: {
        opacity: 1,
        y: '48vh',
        transition: {
            duration: animationSpeedShow,
            type: 'spring',
            stiffness
        }
    }
};

export const searchResultAnimation = {
    hidden: {
        opacity: 0,
        y: 200
    },
    show: {
        opacity: 1,
        y: 130,
        transition: {
            duration: 0.5,
            delay: 0.1
        }
    }
};

export const noResultAnimation = {
    hidden: {
        opacity: 0,
        y: 46,
        transition: {
            duration: 0.1
        }
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.25
        }
    }
};
