import React, { useState } from 'react';
//import { toast } from 'react-toastify';
import TableBooks from './table-books';
import AddBook from './add-book';
import StudentInformation from './student-information';
import BorrowPackage from './borrow-package';
import { AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faBoxOpen } from '@fortawesome/free-solid-svg-icons';

const StudentData = (props) => {
   const [zaduziPaket, setZaduziPaket] = useState(false);

   return (
      <div className='student-data'>
         <StudentInformation />
         <TableBooks />
         <hr style={{ marginBottom: '2rem' }} />
         <div
            className='borrow-type'
            onClick={() => {
               setZaduziPaket(!zaduziPaket);
            }}
         >
            <p>{zaduziPaket ? 'Zaduži knjigu' : 'Zaduži paket'}</p>
            <FontAwesomeIcon
               icon={zaduziPaket ? faBookOpen : faBoxOpen}
               size='2x'
               color='#c30e60'
            />
         </div>
         <AnimatePresence>
            {!zaduziPaket && <AddBook />}
            {zaduziPaket && <BorrowPackage />}
         </AnimatePresence>
      </div>
   );
};

export default StudentData;
